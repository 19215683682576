<!-- 批次結單的 modal -->
<template>
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchCheckOutModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">批次結單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 結單條件 -->
          <div class="row mb-3">
            <div class="col-6">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                name="checkOutSelect"
                id="AllocatedCheckOut"
                value="allocatedCheckOut"
                v-model="batchCheckOutData.action"
              />
              <label class="form-check-label" for="AllocatedCheckOut">
                已配商品結單
              </label>
            </div>
            <div class="col-6">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                name="checkOutSelect"
                id="PreOrderCheckOut"
                value="preOrderCheckOut"
                v-model="batchCheckOutData.action"
              />
              <label class="form-check-label" for="PreOrderCheckOut">
                預購結單
              </label>
            </div>
          </div>
          <!-- 付款方式 -->
          <div class="mb-3">
            <div v-if="!paymentTypes.loading">
              <p class="tw-border-start fw-bolder mb-2">預設付款方式</p>
              <select
                v-if="paymentTypes.data.length > 0"
                class="form-select m-0"
                aria-label="Default select example"
                v-model="batchCheckOutData.paymentTypeId"
              >
                <option value="" selected>不選擇付款方式</option>
                <template v-for="data in paymentTypes.data" :key="data.id">
                  <option :value="data.id" v-if="data.adminEnable">
                    {{ data.name }}
                  </option>
                </template>
              </select>
              <p class="text-danger fw-bolder" v-else>
                目前尚未有任何付款方式!
              </p>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 運送方式 -->
          <div class="mb-3">
            <div v-if="!shippingTypes.loading">
              <p class="tw-border-start fw-bolder mb-2">預設運送方式</p>
              <select
                v-if="shippingTypes.data.length > 0"
                class="form-select m-0"
                aria-label="Default select example"
                v-model="batchCheckOutData.shippingTypeId"
              >
                <option value="" selected>不選擇運送方式</option>
                <template v-for="data in shippingTypes.data" :key="data.id">
                  <option :value="data.id" v-if="data.adminEnable">
                    {{ data.name }}
                  </option>
                </template>
              </select>
              <p class="text-danger fw-bolder" v-else>
                目前尚未有任何付款方式!
              </p>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchCheckOut"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsSelectInfos: {
      type: Object,
      default: {
        show: 'id',
        data: []
      }
    },
    showBatchCheckOutModal:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // modal
      batchCheckOutModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // 批次結單
      batchCheckOutData: {
        action: 'allocatedCheckOut',
        shippingTypeId: '',
        paymentTypeId: ''
      },
      // 運送方式
      shippingTypes: {
        loading: false,
        data: []
      },
      // 付款方式
      paymentTypes: {
        loading: false,
        data: []
      },
    }
  },
  watch: {
    'showBatchCheckOutModal': {
      handler(val, oldVal) {
        if (val) {
          this.initialization() 
          this.getStoreInfo()
          this.batchCheckOutData= {
            action: 'allocatedCheckOut',
            shippingTypeId: '',
            paymentTypeId: ''
          },
          this.batchCheckOutModal.show()
        }
      }
    }
  },
  mounted() {
    this.createModals(['batchCheckOutModal'])
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getShippingTypes,getPaymentTypes}'
        }
      ]
      this.shippingTypes = {
        loading: true,
        data: []
      }
      this.paymentTypes = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 寄送方式
            vm.shippingTypes = {
              loading: false,
              data: storeInfo.shippingTypes
            }
            // 付款方式
            vm.paymentTypes = {
              loading: false,
              data: storeInfo.paymentTypes
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 批次結單
    batchCheckOut() {
      this.$methods.switchLoading('show')
      const data = new FormData()
      let ids = []
      this.propsSelectInfos.data.forEach(item => {
        ids.push(item.id)
      })
      console.log(this.$route.name)
      let siteObjectTypeIndex = ''
      if (this.$route.name === 'Merchandise') siteObjectTypeIndex = 16
      else if (this.$route.name === 'Participant') siteObjectTypeIndex = 13
      data.append('siteObjectTypeIndex', siteObjectTypeIndex)
      data.append('ids', ids.toString())
      data.append('action', this.batchCheckOutData.action)
      if (this.batchCheckOutData.shippingTypeId) data.append('shippingTypeId', this.batchCheckOutData.shippingTypeId)
      if (this.batchCheckOutData.paymentTypeId) data.append('paymentTypeId', this.batchCheckOutData.paymentTypeId)
      this.$api.bill.createInBatches(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.batchCheckOutModal.hide()
          this.$emit('notifyParentFromBatchCheckOut', {
            showBatchCheckOutModal: false,
            clearSelectItems: true,
            reloadDataTable: true
          })
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 關閉 modal
    closeModal() {
      this.$emit('notifyParentFromBatchCheckOut', {
        showBatchCheckOutModal: false,
        clearSelectItems: false,
        reloadDataTable: false
      })
    }
  }
}
</script>